<template>
  <admin-layout>
    <router-view></router-view>
  </admin-layout>
</template>
<script>
import AdminLayout from '@/components/AdminLayout';
export default {
  components: {
    AdminLayout
  }  
}
</script>
<style scoped>

</style>