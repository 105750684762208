<template>
  <a-layout id="admin-layout" style="min-height: 100vh">
    <a-layout-sider collapsible v-model="collapsed" breakpoint="lg" theme="dark">
      <div class="logo" v-if="!collapsed">
        <router-link tag="a" to="/Admin/School">Weekend Worksheets</router-link>
      </div>
      <a-menu theme="dark" :defaultSelectedKeys="[activeNavText]" mode="inline" >
        <template v-if="authenticationRole === 'Admin'">
          <a-menu-item key="1" >
            <router-link tag="a" to="/Admin/School">
              <a-icon type="cluster" />
              <span>School</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="2">
            <router-link tag="a" to="/Admin/Campus">
              <a-icon type="bank" />
              <span>Campus</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="3">
            <router-link tag="a" to="/Admin/Class">
              <a-icon type="read" />
              <span>Class</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="4">
            <router-link tag="a" to="/Admin/Branch">
              <a-icon type="branches" />
              <span>Branch</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="5">
            <router-link tag="a" to="/Admin/Student">
              <a-icon type="team" />
              <span>Student</span>
            </router-link>
          </a-menu-item>
        </template>
        <a-menu-item key="6">
          <router-link tag="a" to="/Admin/Worksheet">
            <a-icon type="file-text" />
            <span>Worksheet</span>
          </router-link>
        </a-menu-item>
        <template v-if="authenticationRole === 'Admin'">
          <a-menu-item key="7">
            <router-link tag="a" to="/Admin/Teacher">
              <a-icon type="user" />
              <span>Teacher</span>
            </router-link>
          </a-menu-item>
        </template>
      </a-menu>
      <a-button 
        type="danger"
        icon="poweroff"
        @click="logout"
        :class="collapsed ? 'logout-collapsed' : 'logout'">
          {{collapsed ? '' : 'Logout'}}
      </a-button>
    </a-layout-sider>
    <a-layout>
      <a-layout-content>
        <slot></slot>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { mapGetters } from 'vuex';
import tools from '@/tools/cookie';
  export default {
    data() {
      return {
        collapsed: false,
      };
    },
    computed: {
      ...mapGetters(['activeNavText', 'authenticationRole'])
    },
    methods: {
      logout() {
        this.$store.commit('setActiveNavText', '1');
        this.$store.commit('setAuthenticationRole', '');
        tools.cookie.set('adminToken', '', 0);
        this.$router.push({name : 'AdminLogin'})
      }
    }
  };
</script>
<style>
.logo {
  height: 32px;
  margin: 16px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.logo > a {
  color: white;
  text-decoration: unset;
}

.mr-10 {
  margin-right: 10px;
}

.icon-size {
  font-size: 20px;
}

.logout {
  position: fixed;
  bottom: 50px;
  width: 200px;
  color: #f5222d;
  font-weight: bold;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.logout-collapsed {
  position: fixed;
  bottom: 50px;
  width: 80px;
  color: #f5222d;
  font-weight: bold;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

</style>